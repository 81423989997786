import ActivityEditor from './index.vue';
import { createLocalVue, shallowMount } from '@vue/test-utils';
import Vuelidate from 'vuelidate';

jest.mock('@/shared/helpers/toast');
jest.mock('@/services');

describe('activityEditor', () => {
  const localVue = createLocalVue();
  localVue.use(Vuelidate);

  let wrapper;
  const stubs = {
    HsGroup: true,
    HsInput: true,
    HsRadio: true,
    HsCheckbox: true,
    HsIcon: true,
    HsButton: {
      name: 'hs-button',
      template: '<button class="hsbutton-stub"></button>',
    },
    BCollapse: true,
    HsMultiselect: true,
    HsFormInvalidFeedback: true,
  };

  const propsData = {
    activity: {
      title: 'title',
      max_attempts: 10,
      min_grade: 100,
      exam_lesson: {
        min_grade: 0,
        unlock_certificate: true,
      },
      show_comments: 'always',
    },
  };

  beforeEach(() => {
    wrapper = mountWrapper(localVue, stubs, propsData);
  });

  describe.each`
    status   | text
    ${true}  | ${'close'}
    ${false} | ${'open'}
  `('when click on activity editor collapse', ({ status, text }) => {
    beforeEach(() => {
      wrapper.setData({ visible: status });
    });

    describe(`and the visible is ${status}`, () => {
      it(`should ${text} collapse`, () => {
        expect(wrapper.vm.visible).toBe(status);

        wrapper.find('#exame-edition-collapse').trigger('click');

        expect(wrapper.vm.visible).toBe(!status);
      });
    });
  });
});

function mountWrapper(localVue, stubs, propsData) {
  return shallowMount(ActivityEditor, {
    localVue,
    stubs,
    propsData,
    mocks: {
      $t: jest.fn(),
    },
  });
}
